import React from 'react'
import { Link, Route, useNavigate } from 'react-router-dom'
import ROUTES from '../../Nav/Routes';
import { useState, useEffect } from 'react';
import axios from 'axios';



function Navbar() {
    const navigate = useNavigate();


    

    const handleClickTop = () => {
        window.scrollTo(0, 0);
    };

    const handleRefresh = () => {
        // window.location.reload();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
        window.scrollTo(0, 0);
    };

    return (
        <div>
            <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close">
                        <span className="icofont-close js-menu-toggle"></span>
                    </div>
                </div>
                <div className="site-mobile-menu-body">
                    <div className="has-children">

                    </div>

                </div>
            </div>
           
            <nav className="site-nav ">
                <div className="afterAbsolute">
                    <div className="container absolute ">
                        <div className="menu-bg-wrap ">
                            <div className="site-navigation  ">
                                <Link to={ROUTES.index.name} onClick={handleRefresh} className="logo m-0 float-start">

                                    <img src="Content/img/Logo3.png" className='logoImage' />
                                </Link>

                                <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
                                    <li><Link to={ROUTES.index.name} onClick={handleRefresh}>Home</Link></li>
                                    <li><Link to={ROUTES.about.name} onClick={handleClickTop}>About</Link></li>
                                    <li><Link to={ROUTES.blog.name} onClick={handleClickTop}>Blog</Link></li>
                                    <li><Link to={ROUTES.gallery.name} onClick={handleClickTop}>Gallery</Link></li>
                                    <li><Link to={ROUTES.contact.name} onClick={handleClickTop}>Contact</Link></li>
                                    <li><Link to={ROUTES.team.name} onClick={handleClickTop}>Team</Link></li>
                                    <li><Link to={ROUTES.donation.name} onClick={handleClickTop}>Donate</Link></li>
                                    {/* <li><Link to={ROUTES.home.name} onClick={handleClickTop}>Login</Link></li> */}
                                    
                                </ul>

                                <a
                                    className="burger light mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
                                    data-toggle="collapse"
                                    data-target="#main-navbar">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>


        </div>
    )
}

export default Navbar;